<template>
  <list-template
    hasAdd
    @onAdd="handleAdd"
    :table-config="tableConfig"
    :table-data="tableData"
    :total="total"
    :loading="loading"
    :current-page="page"
    @onChangePage="handleCurrentChange"
    @onHandle="handleClick"
    @onChange="onChange"
  >
  </list-template>
</template>
<script>
import { tableListMixin } from '@/utils/mixins'
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  name: 'List',
  mixins: [tableListMixin],
  methods: {
    getData() {
      this.getDataApi('/api2/api/operation/student-lock/index')
    },
    handleClick(row) {
      this.$router.push('./detail?id=' + row.id)
    },
    handleAdd() {
      this.$router.push('./add')
    }
  },
  data() {
    return {
      searchConfig: [],
      tableConfig: [
        {
          type: 'number',
          label: '序号',
          width: '80rem'
        },
        {
          prop: 'username',
          label: '学号',
          width: '200rem'
        },
        {
          prop: 'name',
          label: '学生姓名',
          width: '150rem'
        },
        {
          prop: 'created_at',
          label: '更换时间'
        },
        {
          prop: 'creator',
          label: '操作人',
          width: '150rem'
        },
        {
          label: '操作',
          width: '100rem',
          handle: true,
          buttons: [{ type:"view", text:"查看" }]
        }
      ]
    }
  }
}
</script>
